<script setup lang="ts">
import { useArticleKindOneStore } from '@/stores/cms/articleKindStore'
import { isArticleKindStandard } from '@/types/cms/ArticleKind/ArticleKindStandard'
import type { CollabRoom } from '@/types/Collab'
import { useArticleUpdateValidation } from '@/views/cms/article/composables/articleValidations'
import { useCachedUsers } from '@/views/cms/user/composables/cachedUsers'
import { AFormTextField, ARow, useCollabHelpers } from '@anzusystems/common-admin'
import { storeToRefs } from 'pinia'

withDefaults(
  defineProps<{
    collabRoom: CollabRoom
    readonly: boolean
    isModerator: boolean
  }>(),
  {}
)

const { createCollabFieldConfig } = useCollabHelpers()
const { cachedUsers } = useCachedUsers()

const articleKindOneStore = useArticleKindOneStore()
const { article } = storeToRefs(articleKindOneStore)

const { v$ } = useArticleUpdateValidation(article)
</script>

<template>
  <ARow v-if="isArticleKindStandard(article)">
    <AFormTextField
      v-model="article.canonicalUrl"
      :disabled="readonly"
      :v="v$.article.canonicalUrl"
      :collab="createCollabFieldConfig('seo.title', collabRoom, cachedUsers)"
    />
  </ARow>
</template>
