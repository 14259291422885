import { useContentItemKindFactory } from '@/model/cms/factory/ContentItemKind/ContentItemKindFactory'
import { ContentItemDiscriminator } from '@/types/cms/ContentItemKind/ContentItemKind'
import { ArticleListLayoutDefault } from '@/types/cms/ContentItemKind/ContentItemKindArticleList'
import type { ContentItemKindBox } from '@/types/cms/ContentItemKind/ContentItemKindBox'

const { createAbstractContentItemKind } = useContentItemKindFactory()

export function useContentItemKindBoxFactory() {
  const create = (): ContentItemKindBox => {
    return {
      ...createAbstractContentItemKind(),
      title: '',
      url: '',
      box: null,
      itemsLimit: 20,
      listLayout: ArticleListLayoutDefault,
      useOverline: false,
      teleportedIdentifiers: {},
      discriminator: ContentItemDiscriminator.Box,
      _resourceName: 'contentItemKindBox',
    }
  }

  return {
    create,
  }
}
