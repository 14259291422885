import { useAuth } from '@/composables/auth/auth'
import { useUserAdminConfigFactory } from '@/model/cms/factory/UserAdminConfigFactory'
import { useUserAdminConfigFilter } from '@/model/cms/filter/UserAdminConfigFilter'
import {
  createUserAdminConfig,
  fetchUserAdminConfigList,
  updateUserAdminConfig,
} from '@/services/api/cms/userAdminConfig'
import { useArticleKindOneStore } from '@/stores/cms/articleKindStore'
import { UserAdminConfigLayoutType, UserAdminConfigType } from '@/types/cms/UserAdminConfig'
import ArticleWidgetAd from '@/views/cms/article/components/widgets/variant/ArticleWidgetAd.vue'
import ArticleWidgetAdvancedSettings from '@/views/cms/article/components/widgets/variant/ArticleWidgetAdvancedSettings.vue'
import ArticleWidgetAdvancedStatistics from '@/views/cms/article/components/widgets/variant/ArticleWidgetAdvancedStatistics.vue'
import ArticleWidgetArticleBookmarks from '@/views/cms/article/components/widgets/variant/ArticleWidgetArticleBookmarks.vue'
import ArticleWidgetAuthors from '@/views/cms/article/components/widgets/variant/ArticleWidgetAuthors.vue'
import ArticleWidgetBaseSettings from '@/views/cms/article/components/widgets/variant/ArticleWidgetBaseSettings.vue'
import ArticleWidgetBasicStatistics from '@/views/cms/article/components/widgets/variant/ArticleWidgetBasicStatistics.vue'
import ArticleWidgetCustomForm from '@/views/cms/article/components/widgets/variant/ArticleWidgetCustomForm.vue'
import ArticleWidgetDateSettings from '@/views/cms/article/components/widgets/variant/ArticleWidgetDateSettings.vue'
import ArticleWidgetEmpty from '@/views/cms/article/components/widgets/variant/ArticleWidgetEmpty.vue'
import ArticleWidgetIText from '@/views/cms/article/components/widgets/variant/ArticleWidgetIText.vue'
import ArticleWidgetMultiTitle from '@/views/cms/article/components/widgets/variant/ArticleWidgetMultiTitle.vue'
import ArticleWidgetNewsletter from '@/views/cms/article/components/widgets/variant/ArticleWidgetNewsletter.vue'
import ArticleWidgetOverline from '@/views/cms/article/components/widgets/variant/ArticleWidgetOverline.vue'
import ArticleWidgetPaywallSettings from '@/views/cms/article/components/widgets/variant/ArticleWidgetPaywallSettings.vue'
import ArticleWidgetPropagation from '@/views/cms/article/components/widgets/variant/ArticleWidgetPropagation.vue'
import ArticleWidgetRedirect from '@/views/cms/article/components/widgets/variant/ArticleWidgetRedirect.vue'
import ArticleWidgetRelatedEntities from '@/views/cms/article/components/widgets/variant/ArticleWidgetRelatedEntities.vue'
import ArticleWidgetSeoTexts from '@/views/cms/article/components/widgets/variant/ArticleWidgetSeoTexts.vue'
import ArticleWidgetStage from '@/views/cms/article/components/widgets/variant/ArticleWidgetStage.vue'
import ArticleWidgetSupplementaryMedia from '@/views/cms/article/components/widgets/variant/ArticleWidgetSupplementaryMedia.vue'
import { isArray, usePagination } from '@anzusystems/common-admin'
import { storeToRefs } from 'pinia'
import { useI18n } from 'vue-i18n'
import { useDisplay } from 'vuetify'

export const ArticleWidgetVariant = {
  Authors: 'authors',
  Propagation: 'propagation',
  Redirect: 'redirect',
  Ad: 'ad',
  CustomForm: 'customForm',
  AdvancedSettings: 'advancedSettings',
  BasicStatistics: 'basicStatistics',
  AdvancedStatistics: 'advancedStatistics',
  IText: 'iText',
  SeoTexts: 'seoTexts',
  SupplementaryMedia: 'supplementaryMedia',
  MultiTitle: 'multiTitle',
  Newsletter: 'newsletter',
  Stage: 'stage',
  PaywallSettings: 'paywallSettings',
  DateSettings: 'dateSettings',
  BaseSettings: 'baseSettings',
  Overline: 'overline',
  ArticleBookmarks: 'articleBookmarks',
  RelatedEntities: 'relatedEntities',
} as const
export type ArticleWidgetVariantType = (typeof ArticleWidgetVariant)[keyof typeof ArticleWidgetVariant]

export function useArticleWidget() {
  const { t } = useI18n()
  const articleKindOneStore = useArticleKindOneStore()
  const { pinnedWidgets, pinnedWidgetsLoaded } = storeToRefs(articleKindOneStore)
  const { mobile } = useDisplay()
  const { useCurrentUser } = useAuth()
  const { currentUser } = useCurrentUser('cms')

  const getArticleWidgetComponent = (variant: ArticleWidgetVariantType): any => {
    switch (variant) {
      case ArticleWidgetVariant.Ad:
        return ArticleWidgetAd
      case ArticleWidgetVariant.AdvancedSettings:
        return ArticleWidgetAdvancedSettings
      case ArticleWidgetVariant.Authors:
        return ArticleWidgetAuthors
      case ArticleWidgetVariant.BaseSettings:
        return ArticleWidgetBaseSettings
      case ArticleWidgetVariant.CustomForm:
        return ArticleWidgetCustomForm
      case ArticleWidgetVariant.DateSettings:
        return ArticleWidgetDateSettings
      case ArticleWidgetVariant.IText:
        return ArticleWidgetIText
      case ArticleWidgetVariant.MultiTitle:
        return ArticleWidgetMultiTitle
      case ArticleWidgetVariant.Newsletter:
        return ArticleWidgetNewsletter
      case ArticleWidgetVariant.Overline:
        return ArticleWidgetOverline
      case ArticleWidgetVariant.PaywallSettings:
        return ArticleWidgetPaywallSettings
      case ArticleWidgetVariant.Propagation:
        return ArticleWidgetPropagation
      case ArticleWidgetVariant.Redirect:
        return ArticleWidgetRedirect
      case ArticleWidgetVariant.SeoTexts:
        return ArticleWidgetSeoTexts
      case ArticleWidgetVariant.Stage:
        return ArticleWidgetStage
      case ArticleWidgetVariant.BasicStatistics:
        return ArticleWidgetBasicStatistics
      case ArticleWidgetVariant.AdvancedStatistics:
        return ArticleWidgetAdvancedStatistics
      case ArticleWidgetVariant.SupplementaryMedia:
        return ArticleWidgetSupplementaryMedia
      case ArticleWidgetVariant.ArticleBookmarks:
        return ArticleWidgetArticleBookmarks
      case ArticleWidgetVariant.RelatedEntities:
        return ArticleWidgetRelatedEntities
      default:
        return ArticleWidgetEmpty
    }
  }

  const getArticleWidgetTitle = (variant: ArticleWidgetVariantType) => {
    switch (variant) {
      case ArticleWidgetVariant.Ad:
        return t('cms.articleKind.widget.ad.title')
      case ArticleWidgetVariant.AdvancedSettings:
        return t('cms.articleKind.widget.advancedSettings')
      case ArticleWidgetVariant.Authors:
        return t('cms.articleKind.widget.authors')
      case ArticleWidgetVariant.BaseSettings:
        return t('cms.articleKind.widget.baseSettings')
      case ArticleWidgetVariant.CustomForm:
        return t('cms.articleKind.widget.customForm')
      case ArticleWidgetVariant.DateSettings:
        return t('cms.articleKind.widget.dateSettings.title')
      case ArticleWidgetVariant.IText:
        return t('cms.articleKind.widget.iText')
      case ArticleWidgetVariant.MultiTitle:
        return t('cms.articleKind.widget.multiTitle.title')
      case ArticleWidgetVariant.Newsletter:
        return t('cms.articleKind.widget.newsletter')
      case ArticleWidgetVariant.Overline:
        return t('cms.articleKind.widget.overline')
      case ArticleWidgetVariant.PaywallSettings:
        return t('cms.articleKind.widget.paywallSettings')
      case ArticleWidgetVariant.Propagation:
        return t('cms.articleKind.widget.propagation')
      case ArticleWidgetVariant.Redirect:
        return t('cms.articleKind.widget.redirect.title')
      case ArticleWidgetVariant.SeoTexts:
        return t('cms.articleKind.widget.seoTexts.title')
      case ArticleWidgetVariant.Stage:
        return t('cms.articleKind.widget.stage')
      case ArticleWidgetVariant.BasicStatistics:
        return t('cms.articleKind.widget.basicStatistics.title')
      case ArticleWidgetVariant.AdvancedStatistics:
        return t('cms.articleKind.widget.advancedStatistics.title')
      case ArticleWidgetVariant.SupplementaryMedia:
        return t('cms.articleKind.widget.supplementaryMedia')
      case ArticleWidgetVariant.ArticleBookmarks:
        return t('cms.articleKind.widget.articleBookmarks')
        case ArticleWidgetVariant.RelatedEntities:
        return t('cms.articleKind.widget.relatedEntities')
      default:
        return undefined
    }
  }

  const showWidgetOriginal = (variant: ArticleWidgetVariantType) => {
    return pinnedWidgets.value.includes(variant)
  }

  const lazyLoadUserPinnedWidgets = async () => {
    if (pinnedWidgetsLoaded.value !== false) return pinnedWidgetsLoaded.value
    const pagination = usePagination()
    const filter = useUserAdminConfigFilter()

    filter.user.model = currentUser.value?.id || 0
    filter.configType.model = UserAdminConfigType.PinnedWidgets
    filter.layoutType.model = mobile.value ? UserAdminConfigLayoutType.Mobile : UserAdminConfigLayoutType.Desktop
    filter.systemResource.model = 'articleKind'
    try {
      const res = await fetchUserAdminConfigList(pagination, filter)
      if (res.length === 1) {
        pinnedWidgets.value = []
        if (isArray(res[0].data)) {
          const sortedItems = res[0].data.sort((a: any, b: any) => a.position - b.position)
          pinnedWidgets.value = sortedItems.map((item: any) => item.variant)
        }
        pinnedWidgetsLoaded.value = res[0].id
        return pinnedWidgetsLoaded.value
      }
      pinnedWidgetsLoaded.value = true
    } catch (e) {
      pinnedWidgetsLoaded.value = true
    }
    return pinnedWidgetsLoaded.value
  }

  async function delayedSaveUserPinnedWidgets() {
    const { createDefaultUserAdminConfig } = useUserAdminConfigFactory()
    const data = createDefaultUserAdminConfig()
    data.configType = UserAdminConfigType.PinnedWidgets
    data.systemResource = 'articleKind'
    data.layoutType = mobile.value ? UserAdminConfigLayoutType.Mobile : UserAdminConfigLayoutType.Desktop
    data.user = currentUser.value?.id || 0
    data.data = pinnedWidgets.value.map((variant, index) => ({ variant, position: index + 1 }))
    if (pinnedWidgetsLoaded.value === false) {
      return
    } else if (pinnedWidgetsLoaded.value === true) {
      const res = await createUserAdminConfig(data)
      pinnedWidgetsLoaded.value = res.id
      return
    }
    data.id = pinnedWidgetsLoaded.value
    const res = await updateUserAdminConfig(pinnedWidgetsLoaded.value, data)
    pinnedWidgetsLoaded.value = res.id
  }

  function saveUserPinnedWidgets() {
    let timeoutId: ReturnType<typeof setTimeout> | undefined = undefined

    return (function (): void {
      if (timeoutId !== undefined) {
        clearTimeout(timeoutId)
      }

      timeoutId = setTimeout(async () => {
        await delayedSaveUserPinnedWidgets()
      }, 2000)
    })()
  }

  return {
    lazyLoadUserPinnedWidgets,
    saveUserPinnedWidgets,
    getArticleWidgetComponent,
    getArticleWidgetTitle,
    showWidgetOriginal,
  }
}
