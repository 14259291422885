<script setup lang='ts'>
import { ref, nextTick, watch } from 'vue'
import { type Options } from 'highcharts'
import type { CollabRoom } from '@/types/Collab'
import { useI18n } from 'vue-i18n'
import { useArticleKindOneStore } from '@/stores/cms/articleKindStore'
import { storeToRefs } from 'pinia'
import { AChipNoLink, ARow } from '@anzusystems/common-admin'
import { useOwl } from '@/composables/owl/owl'
import { useHighcharts } from '@/services/highcharts/Highcharts'
import { envConfig } from '@/services/EnvConfigService'
import PieChart from '@/views/graph/PieChart.vue'
import { useArticleSumMetricsOneStore } from '@/stores/cms/articleSumMetricsStore'
import { useElementVisibility } from '@vueuse/core'

withDefaults(
  defineProps<{
    collabRoom: CollabRoom
    readonly: boolean
    isModerator: boolean
  }>(),
  {}
)

const chartPageViewRef= ref<Options>({})
const chartQualityReadRef = ref<Options>({})
const chartTimeSpentRef= ref<Options>({})

const articleSumMetricsOneStore = useArticleSumMetricsOneStore()
const { articleSumMetrics } = storeToRefs(articleSumMetricsOneStore)
const { loadArticleSumMetrics } = useOwl()

const { formatTimeSpent } = useHighcharts()

const refreshChartData = async (): Promise<void> => {
  if (!envConfig.owl.enabled || article.value.owlObjectId === null) {
    return
  }

  const articleMetrics = await loadArticleSumMetrics(article.value.owlObjectId)
  if (articleMetrics === undefined) {
    return
  }
  articleSumMetrics.value = articleMetrics

  chartPageViewRef.value.series =  [{
    type: 'pie',
    colors:  ['#FF073A', '#39FF14', '#FBFF00', '#8904B1', '#F4A460', '#00FFFF'],
    data: [
      {
        name: t('cms.articleKind.widget.advancedStatistics.pageView.series.browser'),
        y: articleMetrics.all.viewCount - articleMetrics.app.viewCount
      },
      {
        name: t('cms.articleKind.widget.advancedStatistics.pageView.series.app'),
        y: articleMetrics.app.viewCount
      },
    ]
  }]

  chartQualityReadRef.value.series = [{
    type: 'pie',
    colors:  ['#FF073A', '#39FF14', '#FBFF00', '#8904B1', '#F4A460', '#00FFFF'],
    data: [
      {
        name: t('cms.articleKind.widget.advancedStatistics.qualityRead.series.browser'),
        y: articleMetrics.all.qualityViewCount - articleMetrics.app.qualityViewCount
      },
      {
        name: t('cms.articleKind.widget.advancedStatistics.qualityRead.series.app'),
        y: articleMetrics.app.qualityViewCount
      },
    ]
  }]

  chartTimeSpentRef.value.series =  [{
    type: 'pie',
    colors:  ['#FF073A', '#39FF14', '#FBFF00', '#8904B1', '#F4A460', '#00FFFF'],
    tooltip: {
      pointFormatter: function () {
        if (this.percentage === undefined) {
          return ''
        }

        return `<b>${formatTimeSpent(this.y)}</b> (${this.percentage.toFixed(1)}%)`
      },
    },
    data: [
      {
        name: t('cms.articleKind.widget.advancedStatistics.timeSpent.series.browser'),
        y: articleMetrics.all.timeSpentCount - articleMetrics.app.timeSpentCount
      },
      {
        name: t('cms.articleKind.widget.advancedStatistics.timeSpent.series.app'),
        y: articleMetrics.app.timeSpentCount
      },
    ]
  }]
}

const componentRef = ref<any>(null)
const isVisible = useElementVisibility(componentRef)
const unwatch =  watch(isVisible, async (visible) => {
  if (!visible) {
    return
  }
  unwatch()

  await nextTick()
  await refreshChartData()
})

const articleKindOneStore = useArticleKindOneStore()
const { article } = storeToRefs(articleKindOneStore)
const { t } = useI18n()

const tab = ref('pageView')
</script>

<template>
  <div ref="componentRef">
    <VRow>
      <VTabs
        v-model="tab"
        color="primary"
        class="mb-2"
        grow
      >
        <VTab value="pageView">
          {{ t('cms.articleKind.widget.advancedStatistics.pageView.title') }}
        </VTab>
        <VTab value="qualityRead">
          {{ t('cms.articleKind.widget.advancedStatistics.qualityRead.title') }}
        </VTab>
        <VTab value="timeSpent">
          {{ t('cms.articleKind.widget.advancedStatistics.timeSpent.title') }}
        </VTab>
      </VTabs>
    </VRow>

    <div v-show="'pageView' === tab">
      <VRow>
        <VCol cols="6">
          <div class="d-flex gc-2 align-center mb-3">
            <span>{{ t('cms.articleKind.widget.advancedStatistics.pageView.all') }}: </span>
            <AChipNoLink color="blue">
              {{ articleSumMetrics.all.viewCount }}
            </AChipNoLink>
          </div>
        </VCol>
        <VCol cols="6">
          <div class="d-flex gc-2 align-center mb-3">
            <span>{{ t('cms.articleKind.widget.advancedStatistics.pageView.app') }}: </span>
            <AChipNoLink color="blue">
              {{ articleSumMetrics.app.viewCount }}
            </AChipNoLink>
          </div>
        </VCol>
      </VRow>
      <VRow>
        <VCol cols="12">
          <ARow>
            <PieChart
              :options="chartPageViewRef"
            />
          </ARow>
        </VCol>
      </VRow>
    </div>

    <div v-show="'qualityRead' === tab">
      <VRow>
        <VCol cols="6">
          <div class="d-flex gc-2 align-center mb-3">
            <span>{{ t('cms.articleKind.widget.advancedStatistics.qualityRead.all') }}: </span>
            <AChipNoLink color="blue">
              {{ articleSumMetrics.all.qualityViewCount }}
            </AChipNoLink>
          </div>
        </VCol>
        <VCol cols="6">
          <div class="d-flex gc-2 align-center mb-3">
            <span>{{ t('cms.articleKind.widget.advancedStatistics.qualityRead.app') }}: </span>
            <AChipNoLink color="blue">
              {{ articleSumMetrics.app.qualityViewCount }}
            </AChipNoLink>
          </div>
        </VCol>
      </VRow>
      <VRow>
        <VCol cols="12">
          <ARow>
            <PieChart
              :options="chartQualityReadRef"
            />
          </ARow>
        </VCol>
      </VRow>
    </div>

    <div v-show="'timeSpent' === tab">
      <VRow>
        <VCol cols="6">
          <div class="d-flex gc-2 align-center mb-3">
            <span>{{ t('cms.articleKind.widget.advancedStatistics.timeSpent.all') }}: </span>
            <AChipNoLink color="blue">
              {{ formatTimeSpent(articleSumMetrics.all.timeSpentCount) }}
            </AChipNoLink>
          </div>
        </VCol>
        <VCol cols="6">
          <div class="d-flex gc-2 align-center mb-3">
            <span>{{ t('cms.articleKind.widget.advancedStatistics.timeSpent.app') }}: </span>
            <AChipNoLink color="blue">
              {{ formatTimeSpent(articleSumMetrics.app.timeSpentCount) }}
            </AChipNoLink>
          </div>
        </VCol>
      </VRow>
      <VRow>
        <VCol cols="12">
          <ARow>
            <PieChart
              :options="chartTimeSpentRef"
            />
          </ARow>
        </VCol>
      </VRow>
    </div>

    <ARow>
      <span>{{ t('cms.articleKind.widget.advancedStatistics.owlObjectId') }}: </span>
      <AChipNoLink color="blue">
        {{ article.owlObjectId }}
      </AChipNoLink>
    </ARow>
  </div>
</template>
