<script lang="ts" setup>
import { FILTER_KEY } from '@/composables/system/filterStoreKey'
import { useInboxListFilter } from '@/model/cms/filter/InboxFilter'
import { useBoxSelectActions } from '@/views/cms/inbox/composables/inboxActions'
import { AFormRemoteAutocomplete, isArray, isNull, type IntegerId } from '@anzusystems/common-admin'
import { onMounted, ref, watch } from 'vue'

withDefaults(
  defineProps<{
    label?: string | undefined
    required?: boolean | undefined
    multiple?: boolean
    returnObject?: boolean
  }>(),
  {
    label: undefined,
    required: undefined,
    multiple: false,
    returnObject: false,
  }
)
const modelValue = defineModel<string | IntegerId | string[] | IntegerId[] | null | object>({ required: true })
const ready = ref(false)

const { fetchItems, fetchItemsByIds } = useBoxSelectActions()
const innerFilter = useInboxListFilter()

const storeData = (storeId: string) => {
  if (!localStorage) return
  localStorage.setItem(storeId, JSON.stringify(modelValue.value))
}

const loadStoredData = (storeId: string) => {
  if (!localStorage) {
    ready.value = true
    return
  }
  const stored = localStorage.getItem(storeId)
  if (!stored) {
    ready.value = true
    return
  }
  modelValue.value = JSON.parse(stored)
  ready.value = true
}

const clearStoredData = (storeId: string) => {
  if (!localStorage) return
  localStorage.removeItem(storeId)
}

watch(modelValue, (newValue) => {
  if (isNull(newValue) || (isArray(newValue) && newValue.length === 0)) {
    clearStoredData(FILTER_KEY.inboxAutocomplete)
    return
  }
  storeData(FILTER_KEY.inboxAutocomplete)
})

onMounted(() => {
  loadStoredData(FILTER_KEY.inboxAutocomplete)
})
</script>

<template>
  <AFormRemoteAutocomplete
    v-if="ready"
    v-model="modelValue"
    :required="required"
    :label="label"
    :fetch-items="fetchItems"
    :fetch-items-by-ids="fetchItemsByIds"
    :inner-filter="innerFilter"
    :multiple="multiple"
    :return-object="returnObject"
  />
</template>
