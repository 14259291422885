import { LinkVariantDefault } from '@/components/anzutap/marks/link/composables/LinkVariant'
import type {
  EmbedImageInlineAware,
  EmbedImageInlineCreateUpdateDto,
} from '@/components/anzutap/types/EmbedImageInline'
import { SYSTEM_CMS } from '@/model/systems'
import { dateTimeNow } from '@anzusystems/common-admin'

export function useEmbedImageInlineFactory() {
  const createDefault = (id = ''): EmbedImageInlineAware => {
    return {
      id,
      image: null,
      link: {
        href: '',
        external: false,
        nofollow: false,
        variant: LinkVariantDefault,
        internal: null,
      },
      customData: {},
      discriminator: 'imageInline',
      _resourceName: 'embedKindImageInline',
      _system: SYSTEM_CMS,
      createdAt: dateTimeNow(),
      modifiedAt: dateTimeNow(),
      createdBy: null,
      modifiedBy: null,
    }
  }

  const createCreateUpdateDto = (id = ''): EmbedImageInlineCreateUpdateDto => {
    return {
      id,
      image: null,
      link: {
        href: '',
        external: false,
        nofollow: false,
        variant: LinkVariantDefault,
        internal: null,
      },
      customData: {},
    }
  }

  return {
    createDefault,
    createCreateUpdateDto,
  }
}
