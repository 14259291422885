import { useDocumentFactory } from '@/components/anzutap/factory/DocumentFactory'
import { ArticleDiscriminator } from '@/model/cms/valueObject/ArticleDiscriminator'
import { ArticleResourceNameDefault } from '@/model/cms/valueObject/ArticleResourceName'
import { ArticleStandardIcon } from '@/model/cms/valueObject/ArticleStandardIcon'
import { ArticleStandardLockTypeDefault } from '@/model/cms/valueObject/ArticleStandardLockType'
import { ArticleStatusDefault } from '@/model/cms/valueObject/ArticleStatus'
import { SYSTEM_CMS } from '@/model/systems'
import type { ArticleDates, ArticleSeo, ArticleTexts } from '@/types/cms/ArticleKind/ArticleKind'
import type { ArticleFlagsStandard, ArticleKindStandard, ArticleRelatedEntities } from '@/types/cms/ArticleKind/ArticleKindStandard'
import { dateTimeNow } from '@anzusystems/common-admin'

export function useArticleKindFactory() {
  const createArticleKindStandard = (): ArticleKindStandard => {
    return {
      id: 0,
      discriminator: ArticleDiscriminator.Standard,
      docId: '',
      version: 1,
      status: ArticleStatusDefault,
      texts: createArticleTexts(),
      seo: createArticleSeo(),
      dates: createArticleDates(),
      leadImage: null,
      listingImage: null,
      socialImage: null,
      intention: null,
      site: 0,
      siteGroup: 0,
      rubric: 0,
      desk: 0,
      layoutTemplate: 0,
      articleAuthors: [],
      owners: [],
      keywords: [],
      disabledPromoLinks: [],
      promoLinkItems: [],
      customData: {},
      createdAt: dateTimeNow(),
      modifiedAt: dateTimeNow(),
      createdBy: null,
      modifiedBy: null,
      gallery: null,
      newsletter: null,
      owlObjectId: null,
      attributesStandard: {
        inTrending: true,
        bodyTextCharCount: 0,
        icon: ArticleStandardIcon.None,
        geolocation: {
          latitude: 0,
          longitude: 0,
        },
        lockType: ArticleStandardLockTypeDefault,
        smsCode: null,
        publicVisibleBodyCharCount: 0,
      },
      flagsStandard: {
        enableForum: true,
        enableMultiTitleTest: false,
        enableAds: true,
        enableAdsInContent: true,
        enableVideoAdsInContent: true,
        prAuthor: false,
        enablePromoLinks: true,
      },
      activeStages: [],
      completedStages: [],
      relatedToMe: [],
      relatedArticles: [],
      canonicalUrl: '',
      hasComments: false,
      articleVersions: [],
      mainPage: null,
      firstPublishedBy: null,
      designSettings: null,
      _system: SYSTEM_CMS,
      _resourceName: ArticleResourceNameDefault,
    }
  }

  const createArticleTexts = (): ArticleTexts => {
    const { createEmptyDocument } = useDocumentFactory()

    return {
      headline: '',
      overline: '',
      perex: '',
      body: createEmptyDocument(),
    }
  }

  const createArticleSeo = (): ArticleSeo => {
    return {
      title: '',
      description: '',
      slug: '',
    }
  }

  const createArticleDates = (): ArticleDates => {
    return {
      publishedAt: null,
      firstPublishedAt: null,
      expireAt: null,
      publicPublishedAt: dateTimeNow(),
      publicUpdatedAt: null,
    }
  }

  const createArticleFlagsStandard = (): ArticleFlagsStandard => {
    return {
      enableAds: true,
      enableAdsInContent: true,
      enableVideoAdsInContent: true,
      enablePromoLinks: true,
      prAuthor: false,
      enableForum: true,
      enableMultiTitleTest: false,
    }
  }

  const createArticleRelatedEntities = (): ArticleRelatedEntities => {
    return {
      person: null,
      pages: [],
    }
  }

  return {
    createArticleKindStandard,
    createArticleTexts,
    createArticleSeo,
    createArticleDates,
    createArticleFlags: createArticleFlagsStandard,
    createArticleRelatedEntities,
  }
}
