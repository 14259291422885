import type { AnzuUserAndTimeTrackingAware, IntegerId } from '@anzusystems/common-admin'

export interface TargetPosition extends AnzuUserAndTimeTrackingAware {
  id: IntegerId
  name: string
  identifier: string
  lazyLoadedItemsOffset: number
  stickyItemsBottomOffset: number
  webEntityType: WebEntityTypeType
  _resourceName: 'targetPosition'
  _system: 'cms'
}

export const WebEntityType = {
  ArticleStandard: 'articleStandard',
  ArticleMinute: 'articleMinute',
  Author: 'author',
  Page: 'page',
  Gallery: 'gallery',
  Forum: 'forum',
  Person: 'person',
  Organization: 'organization',
  PersonPage: 'personPage',
  OrganizationPage: 'organizationPage',
} as const
export const WebEntityTypeDefault = WebEntityType.ArticleStandard
export type WebEntityTypeType = (typeof WebEntityType)[keyof typeof WebEntityType]

export interface TargetPositionMinimal {
  id: IntegerId
  title: string
}
